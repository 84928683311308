import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DataTable from 'react-data-table-component';
import * as myConstClass from '../../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareUpRight } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import AuthUser from '../AuthUser';

const MySwal = withReactContent(Swal)

export default function ListDeudas() {
    const {http, catchError, jsonSaLoading, base} = AuthUser();
    const [documentos, setDocumentos] = useState([])
    const [tableShow, setTableShow] = useState([])
    const [name, setName] = useState('');

    const [doc, setDoc ] = useState({})

    const [modalEditar, setModalEditar] = useState(false)
    
    const handleCloseModalEditar = () => {
        setModalEditar(false);
        /* resetFormPago(); */
    }

    const verPago = (cod) => {
        documentos.forEach(function(item, i){
            if(item.akey == cod ){
                /* console.log(item); */
                setDoc(item);
                return;
            }
        });
        handleShowModalEditar();
    }

    const handleShowModalEditar = () => {
        /* documentos.forEach(function(item, i){
            if(item.akey == id ){
                setDoc(item);
                return;
            }
        }); */
        setModalEditar(true);
    }

    useEffect(()=>{
        document.title = "Verificar pagos";
        fetchAlquileres() 
    },[])

    const fetchAlquileres = async () => {
        MySwal.fire(jsonSaLoading(MySwal));
        await http.get(`/${base}/getPendientes`).then(({data})=>{
            MySwal.close();
            setDocumentos(data);
            setTableShow(data);
            console.log(data);
        }).catch(({response:{data}})=>{
            catchError(data);
        });
    }   

    const filter = (e) => {
        const keyword = e.target.value;    
        setName(keyword);
        if (keyword != '') {
            const results = documentos.filter((item) => {
                return item.cliente && item.cliente.toLowerCase().includes(keyword.toLowerCase());
                // item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()), 
                // Use the toLowerCase() method to make it case-insensitive
            });
            setTableShow(results);
        } else {
            setTableShow(documentos);
          // If the text field is empty, show all users
        }
    };

    const confirmarPago = async (e) => {
        e.preventDefault();
        const isConfirm = await MySwal.fire({
          title: 'Desea continuar?',
          text: "Esta por APROBAR este pago",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelmButtonText: 'Regresar',
          confirmButtonText: 'Si, aprobar ahora!'
        }).then((result) => {
          return result.isConfirmed
        });
        if(!isConfirm){
          return;
        }
        MySwal.fire(jsonSaLoading(MySwal, "Confirmando pago"));
        await http.post(
            `/${base}/setPagoAprobado/${doc.akey}`
        ).then(({data})=>{
            MySwal.fire({
                text:data.message,
                icon:"success"
            })
            handleCloseModalEditar();
            fetchAlquileres();          
        }).catch(({response})=>{                
            console.log("ERROR");
            if(response.status===422){
                MySwal.fire({
                    text:response.data.message,
                    icon:"error"
                    })
            }else{
                MySwal.fire({
                text:response.data.message,
                icon:"error"
                })
            }
        })
    }

    const columns = [
        {
            id: 'action',
            name: '',
            maxWidth:'30px !important',
            minWidth:'initial !important',
            compact:true,
            cell: row => (
                <Button variant="outline-primary" 
                    onClick={() => {
                        verPago(row.akey)
                      }}
                    className='btn-sm'>
                    <FontAwesomeIcon icon={faSquareUpRight}/>
                </Button>
            ),
            /* style: {
            	"padding-left": "0px",                
            	"padding-right": "0px"
            }, */
        },
        {
            id: 'cliente',
            name: 'Cliente',
            maxWidth:'190px !important',
            width:'190px !important',
            minWidth:'190px !important',
            selector: row => row.cliente,            
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "10px",                
            	"padding-right": "10px"
            },
        },
        {
            id: 'codRecibo',
            name: 'Numero',
            maxWidth:'120px !important',
            width:'120px !important',
            minWidth:'120px !important',
            selector: row => row.recibo_full,
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'fecha',
            name: 'F Operacion',
            maxWidth:'100px !important',
            width:'100px !important',
            minWidth:'190px !important',
            selector: row => row.fecha_operacion,            
            cell: row => (
                <span> { row.fecha_operacion ? myConstClass.get_date(row.fecha_operacion) : '-' }  </span>
            ),
            sortable: true,
            reorder: true,            
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'monto',
            name: 'Monto',
            maxWidth:'90px !important',
            minWidth:'90px !important',
            selector: row => parseFloat((Math.floor(row.monto * 100)/100).toFixed(2)),            
            cell: row => (
                <span> S/. {(Math.floor(row.monto * 100)/100).toFixed(2)}</span>
            ),
            sortable: true,            
            right: true,
            grow:2,
            reorder: true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "10px"
            },
        },
        {
            id: 'tipopago',
            name: 'Tipo Pago',
            maxWidth:'100px !important',
            minWidth:'100px !important',
            selector: row => row.tipopago_string,
            sortable: true,            
            right: true,
            grow:2,
            reorder: true,
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'destino',
            name: 'Destino',
            maxWidth:'100px !important',
            minWidth:'100px !important',
            selector: row => row.caja_str,
            sortable: true,
            center: true,
            grow:1,
            reorder: true,
            wrap:true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        },
        {
            id: 'colCobrador',
            name: 'Cobrador',
            maxWidth:'220px !important',
            width:'220px !important',
            minWidth:'220px !important',
            selector: row => row.cobrado_por,
            sortable: true,
            center: true,
            wrap:true,
            grow:1,
            reorder: true,
            style: {
            	"padding-left": "5px",                
            	"padding-right": "5px"
            },
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    return (
      <div className="container">
            <h3>Pendientes  de verificar</h3>
            <hr />     
          <div className="row">
            <div className='col-12'>
                <div className="input-group mb-3 input-group-sm">
                    <span className="input-group-text" id="basic-addon1">Buscar</span>
                    <input
                        type="text"
                        value={name}
                        placeholder="" 
                        aria-label="Username"
                        onChange={filter}
                        className="form-control"
                    />
                </div>
                
                {/* <Link className='btn btn-primary mb-2 float-end' to={"/alquiler/create"}>
                    Create Alquiler
                </Link> */}
            </div>
            <div className="col-12">
                <div className="card card-body">
                    <div className="table-responsive">
                    <DataTable
                        columns={columns}
                        data={tableShow}
                        pagination paginationComponentOptions={paginationComponentOptions}
                    />
                    </div>
                </div>
            </div>
            <Modal 
                show={modalEditar}
                onHide={handleCloseModalEditar}            
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >            
                <Modal.Body>
                    <h6 className="text-center text-uppercase"> Verificar pago</h6>                    
                    <hr />
                    <Form onSubmit={confirmarPago}>
                        
                        <div className="form-group">
                            <label >Cliente</label>
                            <label className="form-control"> {doc.cliente} </label>
                        </div>
                        <div className="row">
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Recibo de Cancelac.</label>
                                    <label className="form-control"> {doc.recibo_serie}-{doc.recibo_numero} </label>
                                </div>
                            </div>
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Amortizacion</label>
                                    <label  className="form-control"> S/ {doc.monto} </label>
                                </div>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Registrado</label>
                                    <label className="form-control"> { doc.fecha_emision ? myConstClass.get_date(doc.fecha_emision) : "" } </label>
                                </div>
                            </div>
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Fecha Operac.</label>
                                    <label  className="form-control">{ doc.fecha_operacion ? myConstClass.get_date(doc.fecha_operacion) : "" } </label>
                                </div>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Tipo Pago</label>
                                    <label className="form-control"> {doc.tipopago_string} </label>
                                </div>
                            </div>
                            <div className="col-6">                                
                                <div className="form-group">
                                    <label >Destino</label>
                                    <label  className="form-control"> {doc.caja_str} </label>
                                </div>                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">                                
                                <div className="form-group">
                                    <label >Cobrador</label>
                                    <label className="form-control"> {doc.cobrado_por} </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">                                
                                <div className="form-group">
                                    <label >Documento</label>
                                    <label className="form-control">  { doc.id_documento_string === "FACTURA ELECTRONICA" ? ("FF01-" + doc.doc_numero ) : (doc.id_documento_string === "BOLETA DE VENTA ELECTRONICA" ? ("BB01-"+ doc.doc_numero) : doc.documento_str ) } </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">                                
                                <div className="form-group">
                                    <label >Observacion</label>
                                    <label className="form-control"> {doc.observacion} </label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="form-group">
                            <label className="form-control text-center"> Se realizo en una operacion por el monto de S/ { doc.monto_agrupado ? myConstClass.round_string(doc.monto_agrupado, 2) : '0'}  </label>
                        </div>
                        <br />
                        <img className="img-pago w-100" src={`${myConstClass.URL_APP}/storage/pagos/${doc.imagen}`} alt="" />                        
                        <hr />
                        <Button variant="success" className="mt-2" size="lg" block="block" type="submit" /* onClick={() => createPagoTemp()} */>
                            Aprobar
                        </Button>
                        <Button 
                            variant="outline-primary" 
                            className="mt-2 float-end" 
                            size="lg" 
                            block="block" 
                            type="button"
                            onClick={handleCloseModalEditar}
                        >
                            Regresar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
          </div>
      </div>
    )
}

import { Routes, Route, Link } from 'react-router-dom';
import {useEffect, useState } from 'react';
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
/* import "bootstrap/dist/css/bootstrap.css";
import "../App.css"; */
import * as myConstClass from '../constantes.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShop } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faFilePen } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faFolderTree } from '@fortawesome/free-solid-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

import Home from './home.component';
import AuthUser from './AuthUser';
import Login from "./login.component";

/* CONFIGURACION */
import ConfigCambiarEmpresa from "./views_config/empresa.component";
import RelacionPagos from "./views_config/relacionPagos.component";
import PruebaTest from "./views_config/prueba.component";
/* MANTENEDORES */
import TipoPagoEditar from "./tipopago/edit.component";
import TipoPagoCrear from "./tipopago/create.component";
import TipoPagoList from "./tipopago/list.component";
import CorrelativoTrabajadorEdit from "./persona/editCorrelativo.component";
import CorrelativoTrabajadorList from "./persona/listCorrelativo.component";
import UserList from "./usuario/list.component";
import UserCreate from "./usuario/create.component";
import UserEdit from "./usuario/edit.component";
import UserPassword from "./usuario/password.component";
import ClientesList from "./clientes/list.component";
import ClientesEdit from "./clientes/edit.component";
import ClientesDocumentos from "./clientes/listEstadoCuenta.component";
/* OPERACIONES */
import DeudasList from "./pagos/listDeudas.component";
import PendientesList from "./pagos/listPagosPendientes.component";
import PagosDoneList from "./pagos/listPagosRealizados.component";
import PagarDocumento from "./pagos/pagar.component";
import { VER_APP } from '../constantes.js';

function ModalMenu(props) {    
    const {user} = AuthUser();


    function VerificarButton(props) {
        if (user.rol === "ADMINISTRADOR") {
            return (            
                <div className="col-4">
                    <div className="d-grid gap-2">
                        <Link to={`/pagos/pendientes`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                            <FontAwesomeIcon icon={faCircleCheck} size="2x"/> <br />
                            Verificar
                        </Link>
                    </div>
                </div>
            );
        }
    }

    function MantenedoresButton(props) {
        if (user.rol === "ADMINISTRADOR") {
            return (            
                <>
                    <h6 className="text-start">MATENEDORES</h6>
                    <div className="row">
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/tipopago/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">                   
                                    <FontAwesomeIcon icon={faCreditCard} size="2x"/> <br />
                                    Tipos de pago
                                </Link>
                            </div>
                        </div>                
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/correlativo_trabajador/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faListOl} size="2x"/> <br />
                                    Correlativos
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/documento_caja/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faListOl} size="2x"/> <br />
                                    Documento - Caja
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                            <Link to={`/clientes/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faUserCheck} size="2x"/> <br />
                                    Cliente
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/usuarios/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faUsers} size="2x"/> <br />
                                    Usuarios
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    
    if (user.rol === "ADMINISTRADOR") {
        return (
            <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >      
                <Modal.Body className="text-center">
                    <h3>Menu Principal</h3>
                    <br />                      
                    <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    INICIO
                                </Link>
                            </div>
                        </div>
                    </div>
                    <br />  
                    <h6 className="text-start">OPERACIONES</h6>
                    <div className="row">
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/pagos`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x"/> <br />
                                    E.E.C.C.
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/pagos/pendientes`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faCircleCheck} size="2x"/> <br />
                                    Verificar
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">                    
                            <div className="d-grid gap-2">                        
                                <Link to={`/pagos/realizados`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faFilePen} size="2x"/> <br />
                                    Cobros
                                </Link>
                            </div>
                        </div>
                        <div className="col-6"></div>
                    </div>
                    <hr />
                    <h6 className="text-start">MATENEDORES</h6>
                    <div className="row">
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/tipopago/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">                   
                                    <FontAwesomeIcon icon={faCreditCard} size="2x"/> <br />
                                    Tipos de pago
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/correlativo_trabajador/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faListOl} size="2x"/> <br />
                                    Correlativos
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/documento_caja/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faFolderTree} size="2x"/> <br />
                                    Doc-Caja
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/pagos/pagar/567/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faQuestion} size="2x"/> <br />
                                    Prueba
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                            <Link to={`/clientes/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faUserCheck} size="2x"/> <br />
                                    Cliente
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/usuarios/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faUsers} size="2x"/> <br />
                                    Usuarios
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={props.onHide}>
                    Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }else{
        return (
            <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >      
                <Modal.Body className="text-center">
                    <h3>Menu Principal</h3>
                    <br />                      
                    <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    INICIO
                                </Link>
                            </div>
                        </div>
                    </div>
                    <br />  
                    <h6 className="text-start">OPERACIONES</h6>
                    <div className="row">
                        <div className="col-4">
                            <div className="d-grid gap-2">
                                <Link to={`/pagos`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x"/> <br />
                                    E.E.C.C.
                                </Link>
                            </div>
                        </div>
                        <div className="col-4">                    
                            <div className="d-grid gap-2">                        
                                <Link to={`/pagos/realizados`} onClick={props.onHide} className="btn btn-outline-primary mb-2">
                                    <FontAwesomeIcon icon={faFilePen} size="2x"/> <br />
                                    Cobros
                                </Link>
                            </div>
                        </div>
                        <div className="col-6"></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={props.onHide}>
                    Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    
}
  
function ModalUserConfig(props) {    
    const {token,logout, user, identidad} = AuthUser();
    const logoutUser = () => {
        if(token != undefined){
            logout();
        }
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered        
        >
            <Modal.Body>                
                <h3 className="text-center">
                    <FontAwesomeIcon icon={faUser} size="2x"/><br />
                    {user.nombre}
                </h3>
                <h6 className="text-center">
                    <span className="text-center">{user.username}</span><br />
                    <span className="text-center">{user.email}</span><br />
                    <div className="badge bg-primary text-wrap" style={{width: '7.5rem'}}>
                        {user.rol}
                    </div>
                </h6>
                <Row>
                    <Col>
                        <dl>
                            <dt className="text-center">Brivet</dt>
                            <dd className="text-center">
                                {user.brivetCod != 0 ? user.brivetCod + ' - ' + user.brivetName : "Pendiente de seleccionar" }
                            </dd>
                        </dl>
                    </Col>
                    <Col>
                        <dl>
                            <dt className="text-center">Fcb</dt>
                            <dd className="text-center">
                                {user.fcbCod != 0 ? user.fcbCod + ' - ' + user.fcbName : "Pendiente de seleccionar" }
                            </dd>
                        </dl>
                    </Col>
                </Row>
                <hr />
                <div className="text-center">                    
                    <span className="text-center">{identidad.cobrador} {identidad.cobrador_name}</span><br />
                </div>
                <div className="text-center">
                    <button type="button" className="btn btn-link btn-sm" onClick={logoutUser}>CERRAR SESION</button>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="default" onClick={props.onHide}>
                    Cerrar
                </Button>
            </Modal.Footer> */}
        </Modal>
    );
}

function Auth() {
  
    const {http, user, catchError, base} = AuthUser();
    const [modalMenuShow, setModalMenuShow] = useState(false);
    const [modalUserShow, setModalUserShow] = useState(false);

    const [empresa, setEmpresa] = useState("");

    useEffect(()=>{
        cargarEmpresaSeleccionada();
        /* cargarCobradores(); */
    },[])

    const cargarEmpresaSeleccionada = () => {
        /* console.log(base); */
        setEmpresa(myConstClass.VAR_GLOBAL.empresas[base].name);
    }   

    const cargarCobradores = () => {
        http.get(`/${base}/config/getInit`).then(({data})=>{
            /* if(localStorage.getItem('dataCobradores') == null){
                localStorage.setItem('dataCobradores', '[]');
            } */
            localStorage.setItem('dataCobradores', JSON.stringify(data.cobradores));
            /* setDatosTabla(data) */
        }).catch(({response:{data}})=>{
            catchError(data);
        })
    }
    
    return (
        <>
            <Navbar id="navbar-master" bg="primary">
                <Container>
                    <ButtonGroup aria-label="Basic example">
                        <Button variant="primary" onClick={() => setModalMenuShow(true)}>
                            <FontAwesomeIcon icon={faBars}/>
                        </Button>
                        <Button variant="primary" onClick={() => setModalUserShow(true)}>
                            <FontAwesomeIcon icon={faUser}/> {user.username}
                        </Button>
                    </ButtonGroup>          
                    {/* <Link to={"/"} className="navbar-brand text-white">                    
                    </Link>     */}    
                    <Link style={{float: 'Left'}} to={`/views_config/empresa/`} className="navbar-brand text-white">
                        <FontAwesomeIcon icon={faShop}/> <span id={"input_global-empresa"}>{empresa}</span>
                    </Link>
                </Container>
            </Navbar> 
            <Container className="mt-15">
                <Row>
                    <Col md={12}>
                        <Routes>
                        {/* CONFIGURACION */}
                        <Route path="/views_config/empresa" element={<ConfigCambiarEmpresa />} />
                        <Route path="/documento_caja" element={<RelacionPagos />} />
                        <Route path="/prueba_test" element={<PruebaTest />} />
                        
                        {/* MANTENEDOR TIPO PAGO*/}
                        <Route path="/tipopago/create" element={<TipoPagoCrear />} />
                        <Route path="/tipopago/edit/:id" element={<TipoPagoEditar />} />
                        <Route path="/tipopago/" element={<TipoPagoList />} />

                        {/* MANTENEDOR TRABAJADOR*/}
                        <Route path="/correlativo_trabajador/" element={<CorrelativoTrabajadorList />} />
                        <Route path="/correlativo_trabajador/edit/:id" element={<CorrelativoTrabajadorEdit />} />

                        {/* /*MENTENEDOR USUARIOS */}
                        <Route path="/usuarios/" element={<UserList />} />
                        <Route path="/usuarios/create" element={<UserCreate />} />
                        <Route path="/usuarios/edit/:id" element={<UserEdit />} />
                        <Route path="/usuarios/password/:id" element={<UserPassword />} />

                        {/* /*MENTENEDOR CLIENTES */}
                        <Route path="/clientes/" element={<ClientesList />} />
                        <Route path="/clientes/edit/:id" element={<ClientesEdit />} />                        
                        

                        {/* <Route path="/alquiler/create" element={<CreateAlquiler />} />
                        <Route path="/alquiler/edit/:id" element={<EditAlquiler />} /> */}
                        <Route path="/pagos/pagar/:id" element={<PagarDocumento />} />
                        <Route path="/pagos/realizados" element={<PagosDoneList />} />
                        <Route path="/pagos/pendientes" element={<PendientesList />} />
                        <Route path="/pagos/documentos/:id/:namecliente" element={<ClientesDocumentos />} />
                        <Route path='/pagos' element={<DeudasList />} />
                        <Route path='/' element={<Home />} />
                        {/* <Route path='/login' element={<Login />} /> */}
                        </Routes>
                    </Col>                    
                    <Col md={12}>
                        <div className="text-center">
                            Version {VER_APP}
                        </div>
                    </Col>
                </Row>
            </Container>
            <ModalMenu
                show={modalMenuShow}
                onHide={() => setModalMenuShow(false)}
            />
            <ModalUserConfig
                show={modalUserShow}
                onHide={() => setModalUserShow(false)}
            />
        </>
    );
}

export default Auth;
